import React from 'react'
import { NavLink } from 'react-router-dom'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
//import {loginShow,Userlogout} from '../store/actions/authActions'

import { makeStyles } from "@material-ui/core/styles";

import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles( {
    mainStyle,
    root:{
        marginRight: "10px;",
        marginTop: "30px;",
        maxWidth: "200px;",
        width: "100%;"
  }
  });

const SiteLogo = (props) => {
    const classes = useStyles();
    const {white} = props

/*
    if(white == true)
    {
        return (
            <NavLink to="/">
                <img src="/images/logo-white.svg" className={classes.root}/>
            </NavLink>
        );
    }*/

    return (
            <img src="/images/spried-white.png" className={classes.root}/>
    );
}

export default SiteLogo