import axios from 'axios'
import { getFirebase } from 'react-redux-firebase'



var baseURL = 'https://api.spried.com';
//baseURL = 'http://localhost:5001/spried-iot/us-central1/api/';
/*
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
    baseURL = 'http://localhost:5001/spried-iot/us-central1/api';
}*/

export { baseURL };