import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'

import {Card,CardHeader,Container ,CardMedia,CardContent,CardActions,IconButton,Collapse} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 40px',
    marginTop: '80px',
  },
  CardActions:{
    display:"flex",
    marginTop:"50px",
    padding: "16px"
  },
  SubmitBox:{
    flexGrow:1,
  },
  SubmitButton:{
    background:"#1B263B",
    color:"#fff",
    padding:"10px 20px",
    "&:hover":{
      background:"#888",
    }
  },
  pp:{
    textAlign:"right",
    color:"rgba(0, 0, 0, 0.54)"
  },
  ppLinks:{
    color:"rgba(0, 0, 0, 0.54)",
    textDecoration: "none",

  }
}));


function View({Services}) { 
  const classes = useStyles();
  
  useEffect(() => {
    document.title = 'SPRIED - intrest'
  }, [])

  return (
        <Card sx={{ minWidth: 275 }}>
        <CardHeader
          title="Feedback"
        />
        <CardContent>
        
          <h2>Loading</h2>


          </CardContent>


      </Card>
  );
}



const mapStateToProps = (state, ownProps) => {
  return {
    Services: state.firestore.ordered.Services
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Services', storeAs: 'Services'}
    ]
})
)(View);



