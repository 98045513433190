import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import SiteLogo from "./SiteLogo"
//import {loginShow,Userlogout} from '../store/actions/authActions'

import { makeStyles } from "@material-ui/core/styles";
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MemoryIcon from '@material-ui/icons/Memory';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import DirectionsIcon from '@material-ui/icons/Directions';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MenuIcon from '@material-ui/icons/Menu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles(theme =>({
   ... mainStyle,
    root: {
      flexGrow: 1,
      zIndex:"1000",
      //position: "fixed;",
      width: "100%;"
    }
  }));

  



const Header = ({user}) => {    
    const classes = useStyles();

    const [subMenu, setSubMenu] = React.useState(null);

    const handleSubMenuClick = (event) => {
      setSubMenu(event.currentTarget);
    };

    const handleSubMenuClose = () => {
      setSubMenu(null);
    };
    

    return (
        <div className={classes.root}>
            <header position="static" className={classes.Header}>
                    <Toolbar className={classes.ToolBar}>
                        <SiteLogo/>
                    </Toolbar>
            </header>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(Header)