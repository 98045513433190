import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';

import Service from './views/Service';
import Product from './views/Product';
import ThankYou from './views/ThankYou';

import Privacy from './views/Privacy';
import Terms from './views/Terms';
import Page404 from './views/Page404';

import {Container } from '@material-ui/core';

import Header from "./components/Header"
/*import Contact from './views/Contact';
import Maintenance from './views/Maintenance';
import Product_List from './views/Product/List';
import Product_Details from './views/Product/Details';
*/




const useStyles = makeStyles((theme) => ({
  root: {
  },
  Container:{
    marginTop:"30px"
  }
}));

function App() {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={classes.root}>
            
          <Header/>
            
          <Container  maxWidth="md" className={classes.Container} >
            <Switch>
              <Route exact path='/' component={Service} />
              <Route exact path='/privacy' component={Privacy}/>
              <Route exact path='/terms' component={Terms}/>
              
              <Route exact path='/service' component={Service}/>
              <Route exact path='/service/:id' component={Service}/>

              <Route exact path='/product' component={Page404}/>
              <Route exact path='/product/:id' component={Product}/>
              
              <Route component={Page404}  status={404}/>
            
            </Switch>
          </Container>
      </div>
    </BrowserRouter>
  );
}

export default App;
