import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';


import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { compose } from 'redux'


import {Card,CardHeader,Container ,CardMedia,CardContent,CardActions,IconButton,Collapse} from '@material-ui/core';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import {Divider,Select,MenuItem} from '@mui/material';



const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 40px',
    marginTop: '80px',
  },
  CardActions:{
    display:"flex",
    marginTop:"50px",
    padding: "16px"
  },
  SubmitBox:{
    flexGrow:1,
  },
  SubmitButton:{
    background:"#1B263B",
    color:"#fff",
    padding:"10px 20px",
    "&:hover":{
      background:"#888",
    }
  },
  pp:{
    textAlign:"right",
    color:"rgba(0, 0, 0, 0.54)"
  },
  ppLinks:{
    color:"rgba(0, 0, 0, 0.54)",
    textDecoration: "none",

  }
}));


function View({Services}) { 
  const classes = useStyles();
  
  useEffect(() => {
    document.title = 'SPRIED - intrest'
  }, [])

  return (
        <Card sx={{ minWidth: 275 }}>
        <CardHeader
          title="Feedback"
        />
        <CardContent>
        
          <h2>Thank You</h2>
          <p>Thank you for sending in your feed back and suggestions, we will review it soon. </p>


          </CardContent>

          <CardActions className={classes.CardActions}>
            <Box className={classes.SubmitBox}>
              <Button  href="https://spried.com/" className={classes.SubmitButton}>View other products</Button>
            </Box>
            <Box className={classes.pp}>
              <NavLink  className={classes.ppLinks} to="/privacy">Privacy Policy</NavLink>
              <br/>
              <NavLink className={classes.ppLinks}  to="/terms">Terms of Service</NavLink>
            </Box>
          </CardActions>

      </Card>
  );
}



const mapStateToProps = (state, ownProps) => {
  return {
    Services: state.firestore.ordered.Services
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Services', storeAs: 'Services'}
    ]
})
)(View);



