import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'
import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles";
import mainStyle from "../assets/mainStyle";
const useStyles = makeStyles( {
    ... mainStyle,
    root:{
        textAlign: "center;",
        paddingTop: "20px;",
        marginBottom: "100px;",
        color:"#fff"
    }
});

const View = () => {
    const classes = useStyles();


  
    useEffect(() => {
        document.title = 'SPRIED - Error'
      }, [])
    
      
    return (
            <Container className={classes.root}>
                <h1><strong>404.</strong> Could not find page.</h1>
                <p>We could not find the page you were looking for.</p>
            </Container>
        )
    
}
export default View
